<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      show-date
      :per-page="10000"
      search-label="search"
      vertical-scroll
      :filter-names="filterNames"
      :export-options="['excel', 'csv', 'pdf']"
      @isExport="downloadFiles"
      @filterOption="onFiltersChange"
    >
      <template v-slot:services="{ item }">
        <div class="text-capitalize">
          {{ item.services }}
        </div>
      </template>

      <template #addFilters>
        <v-card>
          <v-select
            v-model="customFilters.terminal_id"
            filled
            dense
            clearable
            :items="terminalList"
            item-value="id"
            item-text="name"
            :label="$t('terminal')"
            @change="onTerminalChange"
          />
        </v-card>
        <v-card>
          <v-select
            v-model="customFilters.fuel_tank_id"
            filled
            dense
            clearable
            :items="fuelTankList"
            item-value="id"
            item-text="name"
            :label="$t('fuel_tank')"
            @change="onFuelChange"
          />
        </v-card>
        <v-card>
          <v-select
            v-model="customFilters.dispenser_id"
            filled
            dense
            clearable
            :items="dispenserList"
            item-value="id"
            item-text="dispenser_identifier"
            :label="$t('dispenser')"
            @change="fetchData"
          />
        </v-card>
      </template>
      <template v-slot:description>
        <section v-if="list.length > 0">
          <div>
            <fuel-chart
              :loading="isLoading"
              :items="list"
            />
          </div>
          <div class="fuel-cards">
            <dashboard-cards
              :is-loading="isLoading"
              :items="dashboardTags"
            />
          </div>
        </section>
      </template>

      <!-- Action Modals -->
      <!-- <template #actionModals> </template> -->
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { debounce } from "lodash";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    FuelChart: () => import("./utils/FuelDispenserChart.vue"),
    dashboardCards: () =>
      import("@/components/sections/DashboardSliderCards.vue"),
    // FuelDispenserCards: () => import("./utils/FuelDispenserCards.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      permissions: {
        // view: "fuel-tanks.view",
        // create: "fuel-tanks.create",
        // edit: "fuel-tanks.edit",
        // delete: "fuel-tanks.delete",
      },
      customFilters: {},
      //   isMounted: false,
      filters: {},
      subFilters: {},
      //   terminal_id: null,

      //
      groupId: "fuel_dispenser_dashboard",
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "fuelDispenserDashboard/getLoading",
      meta: "fuelDispenserDashboard/getMeta",
      dashboardData: "fuelDispenserDashboard/getList",
      terminalList: "terminal/getTerminalDropDown",
      fuelTankList: "fuelTanks/getDropDown",
      dispenserList: "fuelDispenser/getList",
      //
      tenantList: "tenantsManagement/getTenantOptions",
    }),
    filterNames() {
      return [];
    },
    dashboardTags() {
      const arrData = this.dashboardData?.aggregated_data[0];

      return [
        {
          myIcon: "total-filling-cost",
          name: "total_fuel_cost",
          value: arrData.total_fuel_cost,
          unit: this.$options.filters.currencyFormat(
            this.$t("units.fuel_cost"),
            this.authUser
          ),
        },
        {
          // myIcon: "destination-icon",
          myIcon: "total-fillings-volume",
          name: "total_volume_out",
          value: arrData.total_volumn_out,
          unit: "(L)",
        },
        {
          myIcon: "total-filling-count",
          name: "total_filling",
          value: arrData.total_filling_count,
          unit: "(Count)",
        },
      ];
    },
    list() {
      return this.dashboardData?.all_data || [];
    },
    _headers() {
      const headers = [
        {
          text: this.$t("date"),
          align: "left",
          sortable: true,
          value: "date_only",
        },
        {
          text: this.$t("nozzle_number"),
          align: "left",
          sortable: false,
          value: "nozzle_number",
        },
        {
          text: this.$options.filters.currencyFormat(
            this.$t("fuel_cost"),
            this.authUser
          ),
          align: "left",
          sortable: false,
          value: "fuel_cost",
          showAdmin: true,
        },

        {
          text: this.$t("fuel_dispensed"),
          align: "left",
          sortable: false,
          value: "fuel_dispensed",
        },
        {
          text: this.$t("dispenser_sessions_count"),
          align: "left",
          sortable: false,
          value: "dispenser_sessions_count",
        },
      ];
      // computed headers for showing columns to role based
      return headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },
  },
  watch: {
    "options.tenant_id": {
      handler: function (val) {
        if (val) {
          const params = { tenant_id: val };
          this.$store.dispatch("terminal/dropDownList", params);
        } else {
          this.customFilters = {};
          this.$store.commit("terminal/SET_LIST_DROPDOWN", []);
        }
      },
    },
    "options.fleetId": {
      handler: function (v) {
        if (v) {
          const t = this.tenantList.find((r) => r.id == v);
          const tid = t.is_independent_fleet ? t.id : t.parent_tenant_ids[0];
          const params = { tenant_id: tid };
          this.$store.dispatch("terminal/dropDownList", params);
        } else {
          this.customFilters = {};
          this.$store.commit("terminal/SET_LIST_DROPDOWN", []);
        }
      },
    },
    options() {
      this.fetchData();
    },
    customFilters() {
      this.fetchData();
    },
  },
  async mounted() {
    this.$store.dispatch("fuelTanks/setLoadingValue", true);
    if (!this.authUser.isAdmin) {
      const params = { tenant_id: this.authUser.tenant_id };
      await this.$store.dispatch("terminal/dropDownList", params);
    }
    setTimeout(() => {
      if (this.$route?.query?.terminal_id) {
        this.customFilters.terminal_id = parseInt(
          this.$route.query.terminal_id
        );
        this.onTerminalChange(this.customFilters.terminal_id);
      }
      if (this.$route?.query?.fuel_tank_id) {
        this.customFilters.fuel_tank_id = parseInt(
          this.$route.query.fuel_tank_id
        );
        this.onFuelChange(this.customFilters.fuel_tank_id);
      }
      if (this.$route?.query?.dispenser_id) {
        this.customFilters.dispenser_id = parseInt(
          this.$route.query.dispenser_id
        );
      }
    }, 1500);
  },

  beforeDestroy() {
    const params = {
      name: "fuel_dispenser_dashboard",
      filters: { ...this.options, ...this.customFilters },
    };
    this.$store.commit("redirect/SET_FILTERS", params);
    this.$store.commit("fuelDispenserDashboard/SET_LIST", []);
  },
  methods: {
    fetchData: debounce(async function () {
      if (this.customFilters.terminal_id) {
        //  // Cancel any ongoing requests in the same group
        this.cancelRequestGroup();

        // Track the new request
        const controller = this.trackRequest();

        const params = {
          ...this.options,
          ...this.customFilters,
          signal: controller.signal,
        };
        await this.$store
          .dispatch("fuelDispenserDashboard/list", params)
          .then(() => {
            if (this.options.page > (this.meta?.lastPage || 1)) {
              this.options.page = 1;
            }
          });
      }
    }, 1000),
    // async fetchData() {

    // },

    //
    onTerminalChange(v) {
      delete this.customFilters.fuel_tank_id;
      delete this.customFilters.dispenser_id;

      if (v) {
        const params = { terminal_id: v };
        this.$store.dispatch("fuelTanks/dropDownList", params);
      } else {
        this.$store.commit("fuelTanks/SET_LIST_DROPDOWN", []);
      }
      this.fetchData();
    },
    onFuelChange(v) {
      delete this.customFilters.dispenser_id;
      if (v) {
        const params = { ...this.customFilters };
        this.$store.dispatch("fuelDispenser/list", params);
      } else {
        this.$store.commit("fuelDispenser/SET_LIST", []);
      }
      this.fetchData();
    },
    //
    downloadFiles(val) {
      const d = this.options.date.join("--");
      const fileName = `Fuel-Dispenser-Dashboard ${d}`;

      if (val === "csv") {
        this.downloadCSV(this._headers, this.list, fileName);
      } else if (val === "excel") {
        this.downloadExcel(this._headers, this.list, fileName);
      } else if (val === "pdf") {
        this.downloadPDF(this._headers, this.list, fileName);
      }
    },
  },
};
</script>
<style lang="sass" scoped>
:deep .fuel-cards .sectionOneBoxWrap .insideItemBoxWrap .itemBoxes .iconBox span .lg
  width: 2.3rem
:deep .fuel-cards .sectionOneBoxWrap .insideItemBoxWrap .itemBoxes .iconBox span .total-filling-cost
  width: 4.3rem
</style>
